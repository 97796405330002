import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import Img from "gatsby-image"
// import BackgroundImage from 'gatsby-background-image'
// import SolutionsInfoSection from '../../../Components/pageComponents/solutionsInfoSection2'

// import InquireSection from "../../../Components/inquirelink"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/networking/network-security/Network Security Banner.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stock1: file(relativePath: { eq: "products/networking/network-security/1st Network Security Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stock2: file(relativePath: { eq: "products/networking/network-security/2nd Network Security Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Network Security" image={data.banner.childImageSharp.fluid}>
            <div class="px-5 pt-4">
                <h1 className="mx-auto heading1 p-3 text-center">
                    <span>Products We Offer</span>
                </h1>
                <div class="d-flex px-5 pb-4 justify-content-around align-items-center flex-wrap">
                    <h3 class="py-4" style={{ margin: 0, padding: 0, width: '40vw', minWidth: '300px' }}>
                        <div>
                            <ul>
                                <li>Security Internal Network</li>
                                <li>Firewall</li>
                                <li>Anti-Virus</li>
                                <li>Access Control</li>
                                <li>Application Security</li>
                                <li>Behavioral Analytics</li>
                                <li>Email Security</li>
                                <li>Data Loss Prevention</li>
                            </ul>
                        </div>
                    </h3>
                    <div style={{ width: '40vw', minWidth: '300px' }}>
                        <Img fluid={data.stock1.childImageSharp.fluid} />
                    </div>
                </div>
            </div>

            <div class="d-flex mb-5 px-5 justify-content-around align-items-center flex-wrap-reverse">
                <div style={{ width: '40vw', minWidth: '300px' }}>
                    <Img fluid={data.stock2.childImageSharp.fluid} />
                </div>
                <h3 class="py-4" style={{ margin: 0, padding: 0 }}>
                    <div>
                        <ul style={{ width: '40vw', minWidth: '300px' }}>
                            <li>Intrusion Prevention System</li>
                            <li>Mobile Device Security</li>
                            <li>Network Segmentation</li>
                            <li>Unified Threat Management</li>
                            <li>VPN</li>
                            <li>Web Security</li>
                            <li>Email Security</li>
                            <li>Wireless Security</li>
                        </ul>
                    </div>
                </h3>
            </div>

            {/* TODO: what is this */}
            {/* Brands involved with network security = Carousel Type (normal, not the first description) */}

        </Layout>
    )
}